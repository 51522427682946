<template>
  <v-dialog v-model="active" fullscreen>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" text>Reserve{{ hasJury ? ' Another' : ''}} Jury Slot</v-btn>
    </template>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Reserve Jury Slot</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="active = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <p class="mt-4">Please select your instructor and accompanist (if you need one) to show the available time slots. Then choose your instrument and select your desired time slot and the Save button will appear in the bottom-right corner (along with a button to clear the current slot selection).</p>
        <v-row style="margin-top:1em">
          <v-col :cols="12" md="6" lg="4" xl="3">
            <v-autocomplete v-model="instructorFilter" :items="instructors" label="Instructor" item-text="name" item-value="name" outlined hide-details />
          </v-col>
          <v-col :cols="12" md="6" lg="4" xl="3">
            <v-autocomplete v-model="accompanistFilter" :items="accompanists" label="Accompanist" item-text="name" item-value="name" outlined hide-details />
          </v-col>
          <v-col :cols="12" md="6" lg="4" xl="3">
            <v-autocomplete v-model="instrument" :items="instruments" label="Instrument" outlined hide-details />
          </v-col>
          <v-col :cols="12" md="6" lg="4" xl="3">
            <v-checkbox v-model="sophomorePlatform" label="This is my Sophomore Platform" hide-details>
              <template v-slot:append>
                <v-btn color="info" icon @click="sophomorePlatformHelp = !sophomorePlatformHelp" style="margin-top:-8px">
                  <v-icon>fas fa-question-circle</v-icon>
                </v-btn>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-alert v-if="sophomorePlatformHelp" type="info" dismissible @close="sophomorePlatformHelp = false">Your sophomore platform will be a 20-minute slot instead of the usual 10-minute slot. All of the slots that do not have an available slot before or after it have been removed. Click on the slot for the first 10-minutes and the slot after will automatically be added. If you click the last slot then the one prior will be selected.</v-alert>
      </v-card-text>
      <v-card-text>
        <!-- <jury-calendar :term="term" :instructor="instructorFilter" :accompanist="accompanistFilter" :long-slot="sophomorePlatform" :override-event="selectedSlot" @clickEvent="clickEvent"></jury-calendar> -->
        <v-calendar
          :events="filteredEvents"
          :start="start"
          :end="end"
          :weekdays="weekdays"
          locale="en"
          type="week"
          first-interval="30"
          height="806"
          interval-minutes="20"
          interval-count="15"
          @click:event="clickEvent"
          ></v-calendar>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="active = false">Close</v-btn>
        <v-spacer />
        <v-btn text @click="console.log(slots);console.log(filteredEvents)">Log</v-btn>
      </v-card-actions>
      <v-tooltip v-if="selectedSlot.name !== ''" top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" :disabled="instrument === ''" fab bottom right fixed color="success" @click="save">
            <v-icon>fas fa-save</v-icon>
          </v-btn>
        </template>
        <span>Reserve the selected time slot</span>
      </v-tooltip>
      <v-tooltip v-if="selectedSlot.name !== ''" top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" fab bottom right fixed color="error" style="transform: translateX(-64px)" @click="clear">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </template>
        <span>Clear Slot Selection</span>
      </v-tooltip>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed, onMounted, ref } from '@vue/composition-api'
export default {
  props: {
    term: {
      type: String,
      required: true
    },
    hasJury: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root, emit }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const active = ref(false)
    const start = ref('')
    const end = ref('')
    const weekdays = ref([])
    const instructors = ref([])
    const instructorFilter = ref('')
    const accompanists = ref([])
    const accompanistFilter = ref('No Accompanist')
    const instruments = ref([])
    const instrument = ref('')
    const sophomorePlatform = ref(false)
    const sophomorePlatformHelp = ref(false)

    const slots = ref([])
    const longSlots = ref([])
    const filteredEvents = computed(() => {
      if (instructorFilter.value === '') return []
      const arr = (sophomorePlatform.value ? longSlots.value : slots.value)
      return arr.filter(({ instructor, accompanist }) => {
        if (!instructor.includes(instructorFilter.value)) return false
        if (accompanistFilter.value !== 'No Accompanist' && !accompanist.includes(accompanistFilter.value)) return false
        return true
      })
    })

    const service = root.$feathers.service('student/music/jury-slot')
    onMounted(async () => {
      // Load the jury setup which contains lists of the instructors and accompanists
      const { data } = await root.$feathers.service('student/music/jury-setup').find({ query: { term: props.term } })
      if (data.length > 0) {
        instructors.value = data[0].instructors
        accompanists.value = data[0].accompanists
        accompanists.value.splice(0, 0, { name: 'No Accompanist', availability: [] })
        instruments.value = data[0].instruments.map(({ name }) => name)
        const days = data[0].days.map((day) => day.substring(0, 10))
        days.sort()
        console.log(days)
        weekdays.value = []
        days.forEach((date) => {
          const dt = new Date(date)
          dt.setHours(10)
          if (dt.toISOString().substring(0, 10) !== date) dt.setDate(dt.getDate() + 1)
          weekdays.value.push(dt.getDay())
        })
        start.value = days[0]
        end.value = days[days.length - 1]

        console.log({ start: start.value, end: end.value, weekdays: weekdays.value })

        const aggregate = [
          { $match: { term: props.term, active: true, $or: [{ student: null }, { student: { $exists: false } }] } },
          { $sort: { start: 1 } }
        ]
        const { data: events } = await service.find({ query: { aggregate } })
        const temp = events.map(({ _id, start: st, end: en }) => {
          const start = new Date(st)
          const end = new Date(en)
          const instructor = instructors.value.filter(({ availability }) => availability.filter(({ start: s, end: e }) => new Date(s) <= start && new Date(e) >= end).length > 0).map(({ name }) => name)
          const accompanist = accompanists.value.filter(({ availability }) => availability.filter(({ start: s, end: e }) => new Date(s) <= start && new Date(e) >= end).length > 0).map(({ name }) => name)
          return { id: _id, name: 'Open', color: 'grey', start, end, timed: true, instructor, accompanist }
        })
        for (let i = 0; i < temp.length - 1; i++) {
          if (temp[i].end.toISOString() === temp[i + 1].start.toISOString()) {
            const end = new Date(temp[i].end)
            end.setMinutes(end.getMinutes() + 10)
            longSlots.value.push({ ...temp[i], end, nextSlot: temp[i + 1].id })
          }
        }
        slots.value = temp
      }
    })

    const selectedSlot = ref({
      name: ''
    })

    function clickEvent ({ event }) {
      if (sophomorePlatform.value) {
        while (longSlots.value.filter(({ color }) => color === 'orange').length > 0) {
          const index = longSlots.value.findIndex(({ color }) => color === 'orange')
          longSlots.value.splice(index, 1, { ...longSlots.value[index], color: 'grey', name: 'Open' })
        }
      } else {
        while (slots.value.filter(({ color }) => color === 'orange').length > 0) {
          const index = slots.value.findIndex(({ color }) => color === 'orange')
          slots.value.splice(index, 1, { ...slots.value[index], color: 'grey', name: 'Open' })
        }
      }
      event.color = 'orange'
      event.name = 'Pending Slot'
      selectedSlot.value = { ...event, color: 'orange', name: 'Pending Slot' }
    }
    async function save () {
      const { id, end, nextSlot } = selectedSlot.value
      const patch = {
        student: user.value.directoryId,
        instructor: instructorFilter.value,
        instrument: instrument.value,
        accomp: accompanistFilter.value
      }
      if (sophomorePlatform.value) {
        try {
          await service.patch(nextSlot, { active: false })
        } catch (e) {
          console.error(e)
          root.$store.dispatch('main/snackbar', { active: true, color: 'error', text: 'Error removing extra slot: ' + e, timeout: 8000 })
          return
        }
        // Modify the patch for the current slot to extend the end time
        patch.end = end
      }
      try {
        const event = await service.patch(id, patch)
        root.$store.dispatch('main/snackbar', { active: true, color: 'success', text: 'Slot reserved successfully', timeout: 6000 })
        emit('reserved', { event })
        active.value = false
        clear()
        instructorFilter.value = ''
        accompanistFilter.value = ''
        instrument.value = ''
      } catch (e) {
        console.error(e)
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', text: 'Error reserving slot: ' + e, timeout: 8000 })
      }
    }
    function clear () {
      selectedSlot.value = { name: '' }
    }

    return {
      user,
      active,
      start,
      end,
      weekdays,
      instructors,
      instructorFilter,
      accompanists,
      accompanistFilter,
      instruments,
      instrument,
      sophomorePlatform,
      sophomorePlatformHelp,
      slots,
      filteredEvents,
      selectedSlot,
      clickEvent,
      save,
      clear
    }
  }
}
</script>
<style>
.v-calendar-weekly__week {
  height: unset;
}
</style>
